<!-- 雷达库 -->
<template>
  <div
    class="wrap"
    v-loading="loading"
    v-infinite-scroll="load"
    infinite-scroll-distance="0"
    :infinite-scroll-disabled="true"
  >
    <div class="search_wrap">
      <div class="search-input">
        <div class="search">
          <!-- <p>城市选择</p> -->
          期望地区：
          <el-cascader
            v-model="valueCity"
            placeholder="全国"
            @change="handleChange"
            :options="optionsCity"
            separator="-"
            ref="cascaderArr"
          ></el-cascader>

          <em></em>
        </div>
      </div>
    </div>
    <br />

    <div class="info_wrap" v-for="(item, index) in list" :key="index">
      <img :src="item.avatar" alt v-if="item.avatar" />
      <div v-else>
        <img
          src="./../../assets/images/test/boy.png"
          alt
          v-if="item.gender && item.gender === '男'"
        />
        <img src="./../../assets/images/test/girl.png" alt v-else />
      </div>

      <!-- <img v-lazy="item.head" alt :key="item.head" /> -->

      <div class="headSvg">
        <svg-icon
          iconClass="headMan"
          v-if="item.gender && item.gender === '男'"
        ></svg-icon>
        <svg-icon iconClass="headWoman" v-else></svg-icon>
      </div>

      <div style="position: relative">
        <div class="bsaicInfo">
          <p>昵称：{{ item.nick_name }}</p>

          <p v-if="item.truename">姓名：{{ item.truename }}</p>
          <p v-if="item.age">
            年龄：{{ item.age }}
            <span style="margin-left: 20px"
              >学历：{{ jd_edu_name[item.edu_index] }}
            </span>
          </p>

          <p>期望地区：{{ item.home }}</p>
        </div>

        <div class="expect">
          <el-popover
            placement="right"
            width="800"
            trigger="hover"
            :offset="30"
          >
            <el-table :data="item.company_data" height="300">
              <el-table-column
                width="180"
                property="company_name"
                label="浏览公司"
              ></el-table-column>
              <el-table-column width="140" label="浏览公司所在地区">
                <template slot-scope="scope">{{
                  scope.row.company_place_data.city_name +
                  scope.row.company_place_data.district_name
                }}</template>
              </el-table-column>

              <el-table-column width="140" label="浏览公司职位标题">
                <template slot-scope="scope">{{ scope.row.jd_title }}</template>
              </el-table-column>

              <el-table-column width="140" label="浏览公司职位">
                <template slot-scope="scope">{{
                  scope.row.jd_position_name
                }}</template>
              </el-table-column>

              <el-table-column width="140" label="浏览时间">
                <template slot-scope="scope">{{
                  scope.row.browse_time
                }}</template>
              </el-table-column>


            </el-table>

            <!-- <el-tooltip class="item" effect="dark" placement="right"> -->
            <!-- :content="item.expect_place_str" -->

            <span slot="reference">
              <p>
                <svg-icon iconClass="post"></svg-icon>
                <span class="fontColor fontPadding">浏览公司：</span>
                {{ item.company_name_one }}
              </p>

              <p>
                <svg-icon iconClass="site"></svg-icon>
                <span class="fontColor fontPadding">浏览公司所在地区：</span>

                <span>{{ item.company_place_one }}</span>
              </p>

              <p v-if="item.position_name">
                <svg-icon iconClass="error"></svg-icon>
                <span class="fontColor fontPadding">浏览职位：</span>
                <span>{{ item.position_name }}</span>
              </p>
            </span>

            <!-- </el-tooltip> -->
          </el-popover>

          <p>
            <svg-icon iconClass="time"></svg-icon>
            <span class="fontColor fontPadding">更新时间：</span>
            <span>{{ item.update_time }}</span>
          </p>
        </div>
        <div class="look" @click="down(item)">
          <i
            title="下载"
            class="iconfont iconxiazai"
            :style="{ color: item.self_is_download === 1 ? '#ccc' : '#67c23a' }"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import axios from "axios";
import { getradar, downradar, showCity, get_company_place } from "@/api/resume";

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      loading: true,
      valueCity: "",
      optionsCity: [],
      list: [],
      statusList: ["不合格", "未通过", "可面试"],
      wayList: [
        { icon: "iconfont iconfenxiang", color: "#67c23a" },
        {
          icon: "iconfont iconxiazai",
          color: "#67c23a",
        },
      ],
      page: 0,
      jd_edu_name: [
        "学历不限",
        "初中",
        "中专",
        "高中",
        "大专",
        "本科",
        "硕士",
        "博士",
        "MBA",
      ],

      province_id: 0,
      city_id: 0,
      district_id: 0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    handleChange(value) {
      console.log("sssssssss");
      console.log(this.valueCity[0]); //获取当前点击节点的label值

      (this.province_id = this.valueCity[0]),
        (this.city_id = this.valueCity[1]),
        (this.district_id = this.valueCity[2]),
        (this.place_id = Number(value[value.length - 1]));
      this.currentpage = 1;
      this.searchType = 0;
      this.page = 1;
      this.list = [];
      this.getallData();
    },

    getallData() {
      getradar({
        page: this.page,
        province_id: this.province_id,
        city_id: this.city_id,
        district_id: this.district_id,
      }).then((res) => {
        if (res.code === 0) {
          console.log(res);
          if (res.data.radar_list.length === 0) return;
          console.log(this.list);
          let listArr = [];
          listArr = res.data.radar_list;
          listArr.map((item) => {
            console.log(item.company_place_data);
            item.company_name_one = "";
            item.company_place_one = "";

            item.company_data.filter((v, i) => {
              if (i < 1) {
                item.company_name_one = v.company_name;
                item.company_place_one =
                  v.company_place_data.city_name +
                  v.company_place_data.district_name;
              } else {
                return;
              }
            });

            console.log("信息：", item);
            if (item.district_name != "" && item.district_name != null) {
              item.home = item.district_name;
            }

            return item;
          });
          this.loading = false;
          this.list.push(...listArr);
          console.log(this.list);
        }
      });
    },
    down(item) {
      if (item.self_is_download === 1) {
        this.$message({
          message: "已下载，请在简历雷达中查看",
          type: "danger",
        });
        return;
      }
      // console.log(item)
      downradar({ id: item.id }).then((res) => {
        if (res.code === 0) {
          this.$message({
            message: res.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.msg,
            type: "warning",
          });
        }
      });
    },
    load() {
      console.log("先");
      if (this.page == 0) {
        return;
      }
      this.page++;
      console.log(this.page);
      this.getallData();

      // getradar({ page: this.page }).then((res) => {
      //   if (res.code === 0) {
      //     console.log(res);
      //     if (res.data.radar_list.length === 0) return;
      //     console.log(this.list);
      //     let listArr = [];
      //     listArr = res.data.radar_list;
      //     listArr.map((item) => {
      //       console.log(item.company_place_data);
      //       item.company_name_one = "";
      //       item.company_place_one = "";

      //       item.company_data.filter((v, i) => {
      //         if (i < 1) {
      //           item.company_name_one = v.company_name;
      //           item.company_place_one =
      //             v.company_place_data.city_name +
      //             v.company_place_data.district_name;
      //         } else {
      //           return;
      //         }
      //       });

      //       console.log("信息：", item);
      //       if (item.province != "") {
      //         item.home = item.province;
      //       } else if (item.district_name != "" && item.district_name != null) {
      //         item.home = item.district_name;
      //       } else {
      //         item.home = "未获取";
      //       }

      //       return item;
      //     });
      //     this.loading = false;
      //     this.list.push(...listArr);
      //     console.log(this.list);
      //   }
      // });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    showCity({}).then((res) => {
      this.optionsCity = res.data;

      // 默认公司地区
      get_company_place({}).then((res) => {
        console.log("sssssssss");
        console.log(res);

        this.valueCity = [
          res.data.place_data.province_code,
          res.data.place_data.city_code,
          res.data.place_data.district_code,
        ];

        (this.province_id = res.data.place_data.province_code),
          (this.city_id = res.data.place_data.city_code),
          (this.district_id = res.data.place_data.district_code),
          this.page++;
        console.log("created=" + this.page);
        this.getallData();
        
      });
    });

    // getradar({ page: this.page }).then((res) => {
    //   if (res.code === 0) {
    //     console.log(res);
    //     if (res.data.radar_list.length === 0) return;
    //     console.log(this.list);
    //     let listArr = [];
    //     listArr = res.data.radar_list;
    //     listArr.map((item) => {
    //       console.log(item.company_place_data);
    //       item.company_name_one = "";
    //       item.company_place_one = "";

    //       item.company_data.filter((v, i) => {
    //         if (i < 1) {
    //           item.company_name_one = v.company_name;
    //           item.company_place_one =
    //             v.company_place_data.city_name +
    //             v.company_place_data.district_name;
    //         } else {
    //           return;
    //         }
    //       });

    //         console.log("信息：", item);
    //         if (item.province != "") {
    //           item.home = item.province;
    //         } else if (item.district_name != "" && item.district_name != null) {
    //           item.home = item.district_name;
    //         } else {
    //           item.home = "未获取";
    //         }

    //       return item;
    //     });
    //     this.loading = false;
    //     this.list.push(...listArr);
    //     console.log(this.list);
    //   }
    // });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style scoped lang="scss">
/* //@import url(); 引入公共css类 */
.wrap {
  min-height: 80vh;
  @include padding;
  padding-top: 0;
  margin-top: 80px;
}

.smk-fixed {
  padding-top: 20px;
  width: 100%;

  position: fixed;
  z-index: 500;
  background-color: $main_bc;
}
.info_wrap {
  width: 1000px;
  // height: 80px;
  // margin-top: 10px;
  margin: auto;
  margin-bottom: 10px;
  background-color: #fff;
  padding: 20px 20px;
  color: $jd_fontColor;
  font-size: $jd_fontSize;
  /* text-align: center; */
  /* line-height: 200px; */
  display: flex;
  position: relative;
  cursor: pointer;
}
.info_wrap:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.fontPadding {
  padding-left: 10px;
}
p {
  margin: 0;
  line-height: 27px;
}

img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  margin: auto 0px;
}
.headSvg {
  position: relative;
  left: -10px;
  top: 0;
  z-index: 50;
  margin: 10px 0px auto 0px;
}
/*基本信息 */
.bsaicInfo {
  margin-left: 10px;
  float: left;
  width: 300px;
  height: 100%;
}

/* 期望 */
.expect {
  float: left;

  width: 400px;
}
.expect p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.head {
  font-size: 50px;
  color: brown;
}
.is-down {
  width: 50px;
  height: 24px;
  vertical-align: middle;
}
.look {
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
}

.search_wrap {
  text-align: center;
}
</style>
